import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HeroSection from './pages/Home/HeroSection';
import About from './pages/About/About';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    const hardcodedUsername = 'ankit';
    const hardcodedPassword = 'naman';
    
    if (username === hardcodedUsername && password === hardcodedPassword) {
      console.log('Login successful');
      setIsLoggedIn(true);
    } else {
      console.log('Invalid login');
      alert('Invalid login');
    }
  };

  if (!isLoggedIn) {
    return (
      <div>
        <h1>Login</h1>
        <input 
          type="text" 
          placeholder="Username" 
          value={username} 
          onChange={(e) => setUsername(e.target.value)} 
        />
        <input 
          type="password" 
          placeholder="Password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
        />
        <button onClick={handleLogin}>Login</button>
      </div>
    );
  }

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<HeroSection />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<h1>Not found</h1>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

