import React from "react";
import "./Work.css";
import Zigzag from '../../assets/images/snake-design.png';
import c1 from '../../assets/images/c1.png';
import c2 from '../../assets/images/c2.png';
import c3 from '../../assets/images/c3.png';
import c4 from '../../assets/images/c4.png';
import c5 from '../../assets/images/c5.png';

const steps = [
    { title: "Register", description: "Create an account to access our wide range of courses & personalize your learning experience. ", icon: c1 },
    { title: "Choose Lesson", description: "Select the courses that perfectly match your unique interests and learning goals with inelf.", icon: c2 },
    { title: "Schedule", description: "Access all courses at your convenience and learn at your own pace without any time constraints.", icon: c3 },
    { title: "Start Learning", description: "Dive into your chosen course and begin your exciting educational journey with our website.", icon: c4 },
    { title: "Get Certificate", description: "Earn certificate upon completing the course successfully and Showcase your achievement proudly.", icon: c5 }
  ];  
  

const Work = () => {
  return (
    <div className="work-section">
      <h2 className="work-heading">How does it work?<br /></h2>
      <div className="work-content">
        <img src={Zigzag} alt="Design" className="snake-design" />
        {steps.map((step, index) => (
          <div className="work-step" key={index}>
            <div className="work-circle">
              <img src={step.icon} alt={step.title} className="work-icon" />
            </div>
            <div className={`work-text ${index % 2 === 0 ? 'lower' : ''}`}>
              <h3 className="work-title">{step.title}</h3>
              <p className="work-description">{step.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
