import React from 'react';
import FeatureCard from '../../components/FeatureCard/FeatureCard';
import './Features.css';
import lightbulb from '../../assets/images/f1.png';
import book from '../../assets/images/f2.png';
import prize from '../../assets/images/f3.png';
import practical from '../../assets/images/f4.png';
import jobs from '../../assets/images/f5.png';

export default function Features() {
  const cardData = [
    {
      title: 'Innovative teachings methods',
      content: 'Inspiring students through creative, modern teaching techniques.',
      backgroundColor: 'white', // Keep the card color white
      bottomColor: '#fce4ec', // Set bottom color individually
      icon: lightbulb,
    },
    {
      title: 'Wide range courses',
      content: 'Diverse courses designed to cater every learners varied interests. ',
      backgroundColor: 'white',
      bottomColor: '#e1f5fe',
      icon: book,
    },
    {
      title: 'Certifications upon completion',
      content: 'Earn prestigious certificates to proudly display your achievements.',
      backgroundColor: 'white',
      bottomColor: '#f0f4c3',
      icon: prize,
    },
    {
      title: 'Hands-On Experience',
      content: 'Gain real-world skills through practical, hands-on learning activities.',
      backgroundColor: 'white',
      bottomColor: '#e1f5fe',
      icon: practical,
    },
    {
      title: 'Career Opportunities',
      content: 'Access a job portal to find exciting career opportunities.',
      backgroundColor: 'white',
      bottomColor: '#e8f5e9',
      icon: jobs,
    },
  ];

  return (
    <section style={{width:"100%"}} className="features">
      <div className="featuresWrapper">
        <h1 className="featuresTitle"><br />Features</h1>
        <p className="featuresSubtitle"><br />Discover what makes our platform <b>unique.</b> <br />Our website is designed to <b>Enhance</b> your learning experience.</p>
        <div className="featuresGrid">
          {cardData.map((card, index) => (
            <FeatureCard
              key={index}
              title={card.title}
              content={card.content}
              backgroundColor={card.backgroundColor}
              bottomColor={card.bottomColor} // Pass bottom color as a prop
              icon={card.icon}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
