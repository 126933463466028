import './Mission.css'
export default function Mission() {
    return (
        <>
        <div>
            <h1 className="mission_main_heading">Our Mission</h1>
        </div>
      <div className="mission_container">
        <div className="mission_row">
          <div className="mission_box">
            <div className="mission_icon">
              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M3 17h18M3 12h18M3 7h18" />
              </svg>
            </div>
            <div className="mission_content">
              <h3 className="mission_heading">Awesome Design</h3>
              <p className="mission_text">He will continue his coaching until it's time for the kids.</p>
            </div>
          </div>
          
          <div className="mission_box">
            <div className="mission_icon">
              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
              </svg>
            </div>
            <div className="mission_content">
              <h3 className="mission_heading">Easy Customize</h3>
              <p className="mission_text">He will continue his coaching until it's time for the kids.</p>
            </div>
          </div>
        </div>
  
        <div className="mission_row">
          <div className="mission_box">
            <div className="mission_icon">
              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
              </svg>
            </div>
            <div className="mission_content">
              <h3 className="mission_heading">Bug free Code</h3>
              <p className="mission_text">He will continue his coaching until it's time for the kids.</p>
            </div>
          </div>
  
          <div className="mission_box">
            <div className="mission_icon">
              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1v-2zM5 20h14a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z" />
              </svg>
            </div>
            <div className="mission_content">
              <h3 className="mission_heading">Organized Layouts</h3>
              <p className="mission_text">He will continue his coaching until it's time for the kids.</p>
            </div>
          </div>
        </div>

      </div>
      </>
    )
  }