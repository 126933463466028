import React from 'react';
import './FeatureCard.css';

export default function FeatureCard({ title, content, backgroundColor, bottomColor, icon }) {
  return (
    <div className="feature_card" style={{ backgroundColor }}>
      <div className="feature_card-top">
        <div className="image-container">
          <img src={icon} alt={title} />
        </div>
      </div>
      <div
        className="feature_card-bottom"
        style={{ backgroundColor: bottomColor }} // Set bottom background color
      >
        <h2>{title}</h2>
        <p>{content}</p>
      </div>
    </div>
  );
}
