import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "../Slider/Slider.css"

import image1 from '../../assets/images/slider1.png';
import image2 from '../../assets/images/slider2.png';
import image3 from '../../assets/images/slider3.png';

const Slider = () => {
  const category2 = [
    {
      heading: 'Focused Content',
      description: 'For each topic, we check what the content relevant to the core concepts. We then summarize these into a clear, concise and easy to understand format.',
      image: image1,
    },
    {
      heading: 'Look Confident',
      description: 'Each course is animated with high-quality visuals. The result is an engaging and efficient learning experience that prepares you to excel in any exam in just minutes.',
      image: image2,
    },
    {
      heading: 'Quality Check',
      description: 'Every course undergoes a thorough quality check. A team of experts ensures accuracy and effictiveness, guaranteeing you the best learning experience. ',
      image: image3,
    },
  ];

  return (
    <>
      <div className="pp2">
        <h1 className="hh2" style={{margin:"0px"}}>Our commitment to your learning success </h1>
        <p className="hhh2">We invest over <b>100 hours</b> in each course –<br/>so that you understand each topic in just <b>a minute.</b></p>
      </div>

      <div className="poster2 custom-carousel-container">
        <Carousel
          showThumbs={false}
          autoPlay={true}
          transitionTime={3}
          infiniteLoop={true}
          showStatus={false}
          showIndicators={true}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
  const defStyle = { 
    width: 10,   // Adjust the width of the dot
    height: 10,  // Adjust the height of the dot
    marginLeft: 3, 
    marginRight: 3, 
    cursor: "pointer",
    display: 'inline-block', // Ensure dots are displayed inline
    borderRadius: '50%',     // Make the dots circular
    backgroundColor: isSelected ? '#000' : '#ccc', // Change color based on selection
    border: 'none',  // Ensure no border around the dot
  };
  return (
    <span
      style={defStyle}
      onClick={onClickHandler}
      onKeyDown={onClickHandler}
      value={index}
      key={index}
      role="button"
      tabIndex={0}
      aria-label={`Indicator ${index + 1}`}
    />
  );
}}

        >
          {category2.map((item, index) => (
            <div key={index} className="slider-container2">
              <div className="slider-content2">
                <h1 className="heading2">{item.heading}</h1>
                <p className="description2">{item.description}</p>
              </div>
              <div className="image-container2">
                <img
                  src={item.image}
                  alt={item.heading}
                  className="slider-image2"
                />
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
};

export default Slider;