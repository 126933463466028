import React, { Suspense, lazy } from 'react';
import DesignImage from '../../assets/images/design.png';
import AnimationImage from '../../assets/images/animation.png';
import './HeroSection.css';
import Features from './Features.js';

// Lazy loading the components
const Header = lazy(() => import('../../components/Header/Header'));
const LearningSection = lazy(() => import('./LearningSection.js'));
const Slider = lazy(() => import('../../components/Slider/Slider.jsx'));
const LearningExp = lazy(() => import('./LearningExp.js'));
const MovingAnimation = lazy(() => import('./MovingAnimation.js'));
const Footer = lazy(() => import('../../components/Footer/Footer'));

const FeatureBox = ({ title, description, buttonText }) => (
  <div className="feature-box">
    <h5>{title}</h5>
    <p>{description}</p>
    <button className="feature-btn">{buttonText}</button>
  </div>
);

const HeroSection = () => {
  return (
    <div className="landing-page">
      <Suspense fallback={<div>Loading header...</div>}>
        <Header />
      </Suspense>

      <main className="main-section">
        <div className="main-content">
          <div className="main-text">
            <h2>
              We make you <span className="inline-you">Successful</span>.
              <br />
              <img className="you-image" src={DesignImage} alt="u" loading="lazy" />
            </h2>
            <p style={{ color: "#2E0C6E" }}>
              Experience hands-on learning and stunning visuals that empower you to master
              <br />
              essential skills for a bright future!
            </p>
          </div>

          <img
            src={AnimationImage}
            alt="animation"
            className="right-image"
            loading="lazy"
          />
        </div>

        <div className="feature-section">
          <FeatureBox
            title="Learn & Understand"
            description="Explore practical and skill-based learning."
            buttonText="to the learning portal"
          />
          <FeatureBox
            title="Innovative Projects"
            description="Discover with real-world applications."
            buttonText="to the project portal"
          />
          <FeatureBox
            title="Jobs & Internships"
            description="Access opportunities that will help you grow."
            buttonText="to the job portal"
          />
        </div>
      </main>

      {/* Using Suspense to lazy load other sections */}
      <Suspense fallback={<div>Loading sections...</div>}>
        <LearningSection />
        <Slider />
        <LearningExp />
        <Features />
        <MovingAnimation />
      </Suspense>

      <Suspense fallback={<div>Loading footer...</div>}>
        <Footer />
      </Suspense>
    </div>
  );
};

export default HeroSection;
