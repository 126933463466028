import React from 'react';
import './About.css';
import GraduationImage from '../../assets/images/Aboutimage.png';
import LeftFlower from '../../assets/images/flower1.png';
import RightFlower from '../../assets/images/flower2.png';
import Header from '../../components/Header/Header';
import OurValues from '../../components/OurValues/OurValues';
import Footer from '../../components/Footer/Footer';
import Slider from '../../components/SliderAbout/SliderAbout';
import Mission from '../../components/Mission/Mission';
import Work from '../About/Work'

const AboutBanner = () => {
  return (
    <>
    <Header/>
    <div className="about_banner">
      <div className="about_banner-text">
        <h1>Empowering the world <br/>
        to excel at their <br/>
        Studies</h1>
      </div>
      <div className="about_banner-image">
        <img src={GraduationImage} alt="Graduation celebration" />
      </div>
    </div>
    <Mission/>
    <OurValues/>
    <div style={{marginTop:"100px"}}></div>
    <Slider/>
    <div className="words_container">
      <div className="words_flower words_flower-left">
        <img style={{width:"240px"}}src={LeftFlower} alt="" />
      </div>
      <div className="words_content">
        <h2 className="words_heading">A word from all of us</h2>
        <p className="words_text">
          At Canva, we acknowledge the Traditional Custodians of country throughout Australia
          and their connections to land, sea and community. We pay our respect to their elders
          past and present and extend that respect to all Aboriginal and Torres Strait Islander
          peoples today.
        </p>
      </div>
      <div className="words_flower words_flower-right">
        <img src={RightFlower} alt="" />
      </div>
    </div>
    <div style={{marginTop:"100px"}}></div>
    <Work/>
    <div style={{marginTop:"100px"}}></div>
    <Footer/>
    </>
  );
};

export default AboutBanner;
